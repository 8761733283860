<template lang="pug">
InputGroupControl(:label="$t('padding')" type="padding")
</template>
<script>
  import InputGroupControl from './InputGroup.vue';

  export default {
    components: { InputGroupControl },
  };
</script>
