<template lang="pug">
Accordion(fix :fixOptions="fixOptions")
  template(v-if="!hideTitle" #title)
    span {{ labelWithDefault }}
  template(v-if="deviceSelector && !hideTitle" #device)
    DeviceSelector(:hasViewText="false")
  slot(name="beforeColor")
  slot(name="color")
    OmColorInput(
      :label="$t('background')"
      :typeOfComponent="typeOfComponent"
      layout="col"
      dsAllowed
    )
    slot(name="afterBackground")
  slot(name="border")
    Border(:typeOfComponent="typeOfComponent")
  template(#moreOrLess)
    CornerRadius
    slot(name="shadow" v-if="$slots.shadow || shadow")
      Shadow(v-model="shadowComputed" editMobile :typeOfComponent="typeOfComponent")
</template>

<script>
  import DeviceSelector from '@/editor/components/sidebar/components/DeviceSelector.vue';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import Heading from '@/components/Editor/Heading.vue';
  import CornerRadius from '@/components/Editor/Controls/CornerRadius.vue';
  import Border from '@/components/Editor/Controls/Border.vue';
  import Shadow from '@/components/Editor/Controls/Shadow.vue';
  import itemMixin from '@/editor/mixins/item';

  export default {
    components: { Accordion, Heading, CornerRadius, Border, DeviceSelector, Shadow },
    mixins: [itemMixin],
    props: {
      typeOfComponent: {
        type: String,
        required: true,
      },
      deviceSelector: {
        type: Boolean,
        default: true,
      },
      label: {
        type: String,
      },
      shadow: {
        type: Boolean,
        default: false,
      },
      shadowProp: {
        type: String,
        default: 'shadow.type',
      },
      hideTitle: {
        type: Boolean,
        default: false,
      },
      fixOptions: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({ canEditMobile: true }),
    computed: {
      shadowComputed: {
        get() {
          return this.getValueOf('$device.shadow.type');
        },
        set(value) {
          this.setValueOf('$device.shadow.type', value);
        },
      },
      labelWithDefault() {
        return this.label ?? this.$t('backgroundAndBorder');
      },
    },
  };
</script>
